import ApiService from './api.service';
import AuthService from "@/services/auth.service";
import axios from "axios";

class UsersService {
  async allUsers(search) {
    return await ApiService.get('/users', search ? `role=user&search=${search}` : 'role=user');
  }

  async userDetail(id) {
    return await ApiService.get(`/users/${id}`);
  }

  async sendCredentials(id) {
    return await ApiService.get(`/users/${id}/send_credentials`);
  }

  async createUser(params) {
    return await ApiService.post('/users', params);
  }

  async updateUser(id, params) {
    return await ApiService.put(`/users/${id}`, params);
  }

  async deleteUser(id) {
    return await ApiService.delete(`/users/${id}`);
  }

  async changeCompany(id){
    const response = await axios.post(`/change_company/${id}`,{});
    ApiService.storeHeaders(JSON.stringify({token : response.data.token}));
    AuthService.storeCurrentUser(JSON.stringify(response.data.user));
    return response.data;
  }
}


export default new UsersService();
