<template>
  <div id="users-view">
    <div class="content col-12">
      <div class="container grid align-content-start">
        <TabView class="col-12" lazy id="task-tab" :activeIndex="tabActiveIndex" @tab-click="tabClick($event)"
                 @tab-change="tabChange($event)">
          <TabPanel header="Scaduti">
            <Toolbar>
              <template #end>
                <Button class="p-button p-button-rounded" icon="pi pi-download " label="Scarica"
                        @click="downloadCsv()"/>
              </template>
            </Toolbar>

            <Card class="col-12">
              <template #content>
                <h5 class="col-12 text-center" v-if="expired.length == 0">
                  Nessun documento scaduto
                </h5>

                <DataTable :value="expired" v-else>
                  <Column header="Cliente" class="company-name">
                    <template #body="row">
                      {{ row.data.user != null ? row.data.user.name : '-' }}
                    </template>
                  </Column>
                  <Column field="name" header="Documento" class="name"></Column>

                  <Column field="description" header="Descrizione" class="description">
                  </Column>

                  <Column header="Validità" class="validity">
                    <template #body="row">
                      <span>{{
                          this.$moment(row.data.valid_from).format("DD/MM/YYYY")
                        }}</span>
                      <span v-if="row.data.valid_to != null"><br/>{{
                          this.$moment(row.data.valid_to).format("DD/MM/YYYY")
                        }}</span>
                    </template>
                  </Column>

                  <Column class="expired-docs">
                    <template #body="row">
                      <div :class="`${
                          row.data.is_expired ? 'chip-ko' : 'chip-ok'
                        }`"></div>
                    </template>
                  </Column>

                  <Column class="actions">
                    <template #body="row">
                      <div class="flex justify-content-end">
                        <Button class="p-button p-button-text" @click="goToUserDetail(row.data.user_id)">Scheda
                          cliente
                        </Button>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </template>
            </Card>
          </TabPanel>
          <TabPanel header="Scadenze per mese">
            <div>
              <Toolbar>
                <template #start>
                  <AutoComplete v-model="filterMonth" :suggestions="monthSelectItems" :dropdown="true"
                                :forceSelection="true" @complete="loadFilterByMonth($event)"
                                @item-select="filterByMonth($event)"
                                field="label" class="w-100 mt-1 mr-4"/>

                  <AutoComplete v-model="filterYear" :suggestions="yearSelectItems" :dropdown="true"
                                :forceSelection="true" @complete="loadFilterByYear($event)"
                                @item-select="filterByYear($event)"
                                field="label" class="w-100 mt-1 mr-5"/>
                </template>
                <template #end>
                  <Button class="p-button p-button-rounded" icon="pi pi-download" label="Scarica"
                          @click="downloadCsv()"/>
                </template>
              </Toolbar>
            </div>

            <Card class="col-12">
              <template #content>
                <h5 class="col-12 text-center" v-if="expiredByDate.length == 0">
                  Nessun documento scaduto
                </h5>

                <DataTable :value="expiredByDate" v-else>
                  <Column field="name" header="Nome" class="name"></Column>
                  <Column field="description" header="Descrizione" class="description">
                  </Column>

                  <Column header="Validità" class="validity">
                    <template #body="row">
                      <span>{{
                          this.$moment(row.data.valid_from).format("DD/MM/YYYY")
                        }}</span>
                      <span v-if="row.data.valid_to != null"><br/>{{
                          this.$moment(row.data.valid_to).format("DD/MM/YYYY")
                        }}</span>
                    </template>
                  </Column>

                  <Column class="expired-docs">
                    <template #body="row">
                      <div :class="`${
                          row.data.is_expired ? 'chip-ko' : 'chip-ok'
                        }`"></div>
                    </template>
                  </Column>

                  <Column class="actions">
                    <template #body="row">
                      <div class="flex justify-content-end">
                        <Button class="p-button p-button-text" @click="goToUserDetail(row.data.user_id)">Scheda
                          cliente
                        </Button>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </template>
            </Card>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SHOW_LOADER, HIDE_LOADER} from "@/store/html_class.module";

import {DOCUMENTS_EXPIRED} from "@/store/documents.module";

export default {
  data() {
    var today = Date();
    var currentMonthName = this.$moment(today).format("MMMM");

    return {
      tabActiveIndex: 0,

      filterYear: {
        label: this.$moment(today).format("YYYY"),
        value: this.$moment(today).format("YYYY"),
      },
      filterMonth: {
        label:
            currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1),
        value: this.$moment(today).format("MM"),
      },

      monthSelectItems: [],
      yearSelectItems: [],
    };
  },
  computed: {
    expired() {
      return this.$store.state.documents.expired;
    },

    expiredByDate() {
      return this.$store.state.documents.expiredByDate;
    },

    expiredYearsSelect() {
      return this.$store.state.documents.expiredYears;
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    downloadCsv() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      let filename = "scaduti.csv";
      var text = "Nome;Descrizione;Validità;Scaduto;\n";
      var i = 0;
      var item;

      if (this.tabActiveIndex == 0) {
        for (i = 0; i < this.expired.length; i++) {
          item = this.expired[i];
          text += `${item.name};${item.description};${this.$moment(
              item.valid_from
          ).format("DD/MM/YYYY")} - ${this.$moment(item.valid_to).format(
              "DD/MM/YYYY"
          )};${item.is_expired ? "Scaduto" : ""};\n`;
        }
      } else {
        for (i = 0; i < this.expiredByDate.length; i++) {
          item = this.expiredByDate[i];
          text += `${item.name};${item.description};${this.$moment(
              item.valid_from
          ).format("DD/MM/YYYY")} - ${this.$moment(item.valid_to).format(
              "DD/MM/YYYY"
          )};${item.is_expired ? "Scaduto" : "NO"};\n`;
        }
      }

      let element = document.createElement("a");
      element.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },

    tabChange(e) {
      this.tabActiveIndex = e.index;
    },

    tabClick(e) {
      this.tabActiveIndex = e.index;
    },

    async filterByMonth() {
      if (this.filterYear.value !== null && this.filterMonth.value !== null) {
        this.$store.dispatch(SHOW_LOADER);
        await this.fetchExpiredByDate({
          month: this.filterMonth.value,
          year: this.filterYear.value,
        });
        this.$store.dispatch(HIDE_LOADER);
      }
    },

    loadFilterByMonth() {
      setTimeout(() => {
        this.monthSelectItems = [
          ...[
            {label: "Gennaio", value: 1},
            {label: "Febbraio", value: 2},
            {label: "Marzo", value: 3},
            {label: "Aprile", value: 4},
            {label: "Maggio", value: 5},
            {label: "Giugno", value: 6},
            {label: "Luglio", value: 7},
            {label: "Agosto", value: 8},
            {label: "Settembre", value: 9},
            {label: "Ottobre", value: 10},
            {label: "Novembre", value: 11},
            {label: "Dicembre", value: 12},
          ],
        ];
      }, 250);
    },

    async filterByYear() {
      if (this.filterYear.value !== null && this.filterMonth.value !== null) {
        this.$store.dispatch(SHOW_LOADER);
        await this.fetchExpiredByDate({
          month: this.filterMonth.value,
          year: this.filterYear.value,
        });
        this.$store.dispatch(HIDE_LOADER);
      }
    },

    loadFilterByYear() {
      setTimeout(() => {
        const years = [];
        for (var i = 0; i < this.expiredYearsSelect.length; i++) {
          years[i] = {
            label: this.expiredYearsSelect[i],
            value: this.expiredYearsSelect[i],
          };
        }
        this.yearSelectItems = [...years];
      }, 250);
    },

    async fetchExpiredByDate(params) {
      return this.$store
          .dispatch(DOCUMENTS_EXPIRED, params)
          .catch((err) => {
            this.isFormVisible = false;
            this.errorCallback(err);
          })
    },

    async fetchExpired() {
      return this.$store
          .dispatch(DOCUMENTS_EXPIRED, {})
          .catch((err) => {
            this.isFormVisible = false;
            this.errorCallback(err);
          })
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },

    goToUserDetail(userId) {
      this.$router.push({
        name: "documents",
        params: {id: userId},
      });
    },
  },

  async mounted() {
    this.$store.dispatch(SHOW_LOADER);
    await Promise.all([this.fetchExpired(), this.fetchExpiredByDate({
      month: this.filterMonth.value,
      year: this.filterYear.value,
    })])
    this.$store.dispatch(HIDE_LOADER);
  },

  name: "ExpiredDocuments",
};
</script>

