import ApiService from "./api.service";

class DocumentsService {
  async allDocuments(params) {
    const userId = params.userId;
    delete params.userId;
    return await ApiService.post(`/users/${userId}/documents`, params);
  }

  async expiredDocuments(params) {
    if (params["year"] === undefined || params["month"] === undefined) {
      return await ApiService.get(`/documents/expired`);
    }

    return await ApiService.get(
      `/documents/expired?year=${params["year"]}&month=${params["month"]}`
    );
  }

  async createDocument(params) {
    return await ApiService.postMultipart(`/documents`, params);
  }

  async deleteDocument(id) {
    return await ApiService.delete(`/documents/${id}`);
  }
}

export default new DocumentsService();
